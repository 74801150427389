import "./VideoStyles.css";

function VideoData(props) {
  return (
    <div className="t-card">
      <div className="t-image">
      <h4>{props.heading}</h4>
        <iframe width="100%" height="100%" src={props.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default VideoData;
