import "./HomeHeroStyles.css";
import homeImg from '../assets/home.jpg'
import homeImg1 from '../assets/cloud-blue-sky.jpg'
import homeImg2 from '../assets/young-night.jpg'
import TypeWriter from "./TypeWriter";

function HomeHero(props) {
  return (
    <>
      {/* {<div className={props.cName}>
        <img src={props.heroImg} alt="heroImg" />
        <div className="hero-text">
          <h1>{props.title}</h1>
          <p>{props.text}</p>
          <a className={props.btnClass} href={props.url}>
            {props.buttonText}
          </a>
        </div> 
        </div>} */}
        {
          <div className={props.cName}>
          <img src={homeImg2} alt="heroImg" />
          {/* <video autoPlay loop muted id='video'>
            <source src={backgroundVideo} type='video/mp4'/>
          </video> */}
          <div className="hero-text">
          <h1>{props.title}</h1>
          <p>{props.text}</p> 
          
          {/* <a className={props.btnClass} href={props.url}>
            {props.buttonText}
          </a> */}
          </div>
        </div>
        }
        {/* <h1>Your Journey Your Story</h1>
        <p>Choose Your Favourite Destination.</p>
        <a href="index.html">Travel Plan</a> */}
    </>
  );
}

export default HomeHero;
