import Navbar from "../components/Navbar";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";

function About() {
  return (
    <div>
      <Navbar />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default About;
