import React from "react";
import Mountain1 from "../assets/Studio_Side_View.jpg";
import Mountain2 from "../assets/Studio_Front_View.jpg";
import Mountain3 from "../assets/Flying_Rear_View.jpg";
import Mountain4 from "../assets/Parachute_Open.jpg";
import Image5 from "../assets/Design_Front_View.jpg"
import Image6 from "../assets/Image_Flying.jpg"
import DestinationData from "./FeatureData";

const Features = () => {
  return (
    <>
      <div className="destination">
        <h1>Popular Features</h1>
        <DestinationData
          className="first-des"
          heading="Compact Design"
          text="A standout feature of the Paradrone’s design is its flexible dome-shaped wing. This new and revolutionary wing design enables it to have a much more compact wing span while maintaining the required lift for flight and gliding.  The flexible nature of the wing also allows it to be collapsed, providing greater reductions to drag in quad-mode and greater ease of storage once landed."
          img1={Mountain1}
          img2={Mountain2}
        />

        <DestinationData
          className="first-des-reverse"
          heading="Highly Versatile"
          text="Equipped with two unique flight modes, the Paradrone offers a customizable flight experience. The first flight mode, known as 'Horizontal Flight mode,' uses the Paradrone's wing for lift and two forward-facing motors for thrust. In this mode, the Paradrone operates akin to a conventional airplane, requiring 'runway space' for takeoff and landing. In exchange, it achieves extended flight time due to the use of fewer motors compared to the second flight mode. The second flight mode, named 'Quad-mode', utilizes the Paradrone's four vertical motors for both upward lift and forward thrust. In this mode, the Paradrone behaves like a quadcopter drone, enabling greater maneuverability and the ability to take off and land vertically. What truly sets the Paradrone apart however is that it is capable of switching seamlessly between these two flight modes, even while in the air."
          img1={Mountain3}
          img2={Mountain4}
        />

        <DestinationData
          className="first-des"
          heading="Precision in Design"
          text="The Paradrone strikes the perfect balance between strength and weight for optimal performance. Engineered for maximum glidability, it boasts a lightweight yet robust construction with carbon fiber, aluminum, and plastic components."
          img1={Image5}
          img2={Image6}
        />
      </div>
    </>
  );
};

export default Features;
