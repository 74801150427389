import Navbar from "../components/Navbar";
import HomeHero from "../components/HomeHero";
import Destination from "../components/Features";
import Footer from "../components/Footer";
import Trip from "src/components/Videos";
import homeImg from "../assets/home.jpg"

function Home() {
  return (
    <>
      <Navbar />
      <HomeHero
        cName="hero"
        heroImg={homeImg}
        title="Welcome to our website, where innovation takes flight with the Paradrone.  Representing an entirely new form of VTOL aircraft the Paradrone is a first of its kind."
        btnClass="show"
        buttonText="Explore Para Drone"
        url="/"
      />
      <Destination />
      <Trip />
      <Footer />
    </>
  );
}

export default Home;
