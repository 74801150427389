// ToastMessage.js
import React, { useState, useEffect } from 'react';

const ToastMessage = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
      onClose(); // Call the onClose function when the timeout occurs
    }, 3000); // Adjust the duration as needed (in milliseconds)

    return () => clearTimeout(timeoutId);
  }, [onClose]);

  return (
    <div style={{ display: visible ? 'block' : 'none', position: 'realtive', bottom: '20px', right: '20px', padding: '10px', background: '#333', color: '#fff', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
      {message}
    </div>
  );
};

export default ToastMessage;
