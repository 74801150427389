import "./FooterStyles.css";
import ContactForm from "./ContactForm";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="top">
          <div>
            <h1>Para Drone USA</h1>
          </div>
          <div>
            <a href="https://www.facebook.com/profile.php?id=100067384804854" target="_blank">
              <i className="fa-brands fa-facebook-square"></i>
            </a>
          </div>
        </div>

        { <div className="bottom">
          <div>
            <h4>Project</h4>
            <a href="https://patents.google.com/patent/US11198506B2/en?oq=11%2c198%2c506" target="_blank">Patent</a>
            <a href="http://bankonip.com/morrison23001/" target="_blank">BankOnIP(Media Kit)</a>
          </div>
          <div>
            <h4>Community</h4>
            <a href="https://www.facebook.com/profile.php?id=100067384804854" target="_blank"> Facebook</a>
          </div>
          <div>
            <h4>Help</h4>
            <a href="/contact">Contact Us</a>
          </div>
        </div> }
      </div>
    </>
  );
};

export default Footer;
