import "../components/AboutUsStyles.css";

function AboutUs() {
  return (
    <div>
      <br></br>
      <br></br>
    <div className="about-container">
      <div className="heading">
      <h1>About Us</h1>
      </div>
      <p>
      Since its inception in 1990, the Paradrone project has been fueled by the ambition to create an innovative category of compact, safe, and versatile aircraft. Originally developed as a gas-powered prototype, numerous refinements have been made over the years.  Through these refinements the Paradrone has become what it is today, a fully electric, highly versatile, and acrobatically capable RC model aircraft. Although presently developed for the  RC hobbyist market, the ultimate goal is to leverage the Paradrone design to create a full-size piloted version.
      <br></br>
      <br></br>
      In recent years, there has been a growing interest in using multicopter drone designs for manned aircraft. While these designs offer high maneuverability, they often lack the ability to glide. We see this as a significant safety concern because, in the event of a motor failure, complete loss of control would occur since these aircraft rely on their motors for both lift and thrust. In our view, for a personal aircraft to be truly viable for the general public, it must have the capability to glide or auto-rotate, providing inherent control in case of a loss of thrust. The Paradrone design, we believe, offers a potential solution to this challenge by maintaining the maneuverability and compactness of a drone while having the ability to glide like an airplane.
      <br></br>
      <br></br>
      We are actively seeking individuals or organizations in the RC hobbyist or aviation fields who are interested in exploring the possibility of leasing or purchasing the Paradrone design. If you are interested, we invite you to reach out to us through the 'Contact Us' section on our website below.
      </p>
      <div className="video-container">
      <iframe width="725" height="405" src="https://www.youtube.com/embed/sMUzwoqEHNo?si=dCZ2pGOGL8k4lQH5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      </div>
      </div>
    
  );
}

export default AboutUs;
