import "../components/ContactFormStyles.css";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ToastMessage from './ToastMessage';

function ContactForm() {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSuccess(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsFailed(true);
        },
      );
  };

  return (
    <>
      <div className="form-container">
      {isSuccess && <ToastMessage message="Form submitted successfully!" onClose={() => setIsSuccess(false)} />}
      {isFailed && <ToastMessage message="Cannot Submit Form, Come Back Later!" onClose={() => setIsFailed(false)} />}
        <h1>Send us a message!</h1>
        <form ref={form} onSubmit={sendEmail}>
        <input placeholder="Name" name="user_name" required/>
        <input placeholder="Email" name="user_email" required/>
        <input placeholder="Subject" name="subject" required/>
        <textarea placeholder="Message" rows="4" name="message" required></textarea>
        <button type="submit">Send Message</button>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
