import "./VideoStyles.css";
import Video from "./VideoData";

function Videos() {
  return (
    <div className="video">
      <h1>Videos</h1>
      <p>Get to know us more.</p>
      <div className="videocard">
        <Video
          url="https://www.youtube.com/embed/Nn-gsHGKYTg?si=wdL5xkC8IfWgUVuD"
          heading="Parachute Opening & Closing"
          text="Websites and blogs dedicated to technology, gadgets, and drones often publish detailed reviews. Look for reputable sources that provide comprehensive information, including specifications, features, pros, and cons."
        />
        <Video
          url="https://www.youtube.com/embed/J2JVw5_w_y4?si=-hbXOmu4vwd9DRmF"
          heading="Demo of Aircraft Versatility"
          text="Many tech enthusiasts and drone experts post video reviews on platforms like YouTube. These reviews can give you a visual understanding of the drone's performance, capabilities, and flight experience."
        />
        <Video
          url="https://www.youtube.com/embed/bw0q2Q4GT-Y?si=s39Yd_njYD5dUFY2"
          heading="Aircraft Gliding Video"
          text="Websites like Amazon often have customer reviews and ratings for drones. Reading through user experiences can provide insights into real-world usage and potential issues."
        />
      </div>
    </div>
  );
}

export default Videos;
